.root {
  position: relative;

  .extraConfig {
    position: absolute;
    display: flex;
    align-content: flex-start;
    top: 4.75rem;
    left: 0.1rem;
    width: max-content;
  }
}
