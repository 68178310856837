.root {
  width: 1px;
  border-left: 1px dashed #c4c4c4;
  margin-left: 20px;
}

.small {
  height: 3rem;
}

.medium {
  height: 6.6rem;
}

.large {
  height: 10.4rem;
}
