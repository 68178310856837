@import 'src/styles/colors';

.root {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .right {
      display: flex;
      align-items: center;

      .metadata {
        font-size: 0.875rem;
        font-style: italic;
        text-align: right;

        .author {
          color: $mui-primary;
        }
      }
    }
  }

  .processingChip {
    color: $blue-focus;
    background-color: $light-blue;
    margin: 0 1rem;
    font-weight: 700;
    font-size: 12px;

    .processingIcon {
      font-size: 18px;
      animation: rotate 1s linear infinite forwards;
    }
  }

  .fileIconChip {
    opacity: 0.7;
    background-color: inherit;
    font-size: 16px;
  }

  .successChip {
    color: $dark-green;
    background-color: $light-green;
    margin: 0 1rem;
    font-weight: 700;
    font-size: 12px;
  }

  .successIcon {
    font-size: 18px;
  }

  .datePicker input {
    width: 12ch;
    padding: 0.5rem 1rem;
  }

  .loiSelect {
    width: 15rem;

    :global(.MuiListItemIcon-root) {
      display: flex;
      align-items: center;
    }
  }

  .trafficEditBox {
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    margin-left: 0;
    padding: 0.5rem 1.5rem 1rem 1.5rem;

    .legend {
      color: #666666;
      margin-left: -0.5rem;
      padding: 0 0.5rem;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
