.campaign {
  position: relative;
  display: flex;
  flex-direction: column;

  .extraConfig,
  .terminationConfig {
    position: absolute;
    display: flex;
    align-content: flex-start;
    width: max-content;
  }

  .extraConfig {
    top: 4.75rem;
    left: -0.5rem;
  }

  .terminationConfig {
    top: 8.5rem;
    left: 0.1rem;

    .datePicker input {
      width: 12ch;
      padding: 0.5rem 1rem;
    }
  }
}
