.root {
  width: 100%;
  margin: 0 0.5rem;

  .gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
    margin-top: 0.25rem;
  }

  .addMetadataBtn {
    width: fit-content;
    justify-content: flex-start;
    padding: 0.25rem 1rem;
    margin-left: -0.65rem;
  }
}

.newFieldInput input {
  margin-left: -0.5rem;
}
