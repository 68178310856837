/*
 * Official Assurance color palette:
 * https://www.figma.com/file/xW94ag6omoytMGxFGXCL0E/A.com-StyleGuide?node-id=0%3A1
 */

// should match the MUI theme colors defined in src/styles/theme.ts
$mui-primary: #0082cb;
$mui-secondary: #ff4237;
$mui-success: #00d398;

// common color palette that we use
$blue-focus: #005c8d;
$light-blue: #a3d7f5;
$dark-blue: #071f2b;
$purple: #5a2b83;
$dark-purple: #2f1a45;
$red: #ff4237; // use this red for numbers
$success: #00ad60;
$warn: #ea9b26;
$alert: #dd2d30; // use this red for error messages & texts
$white: #fff;
$cool-grey: #f3f3f3;
$light-grey: #b5b5b5;
$light-green: #cae6b2;
$medium-grey: #999;
$dark-grey: #555;
$dark-green: #42602a;
