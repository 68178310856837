.filterType {
  font-weight: bold;
  margin-top: 1rem;
}

.divider {
  margin-top: 1rem;
  margin-bottom: 0.25rem;
}

.loiControl img {
  margin-left: -2px;
  margin-right: -1px;
}
