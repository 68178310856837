.root {
  display: flex;
  align-items: center;

  .icon {
    display: flex;
    align-items: center;

    svg {
      font-size: 47px;
      margin-left: -3px;
      margin-right: 0.4rem;
    }
  }
}
