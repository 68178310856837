@import 'src/styles/colors';

.root {
  padding: 0.25rem 1rem;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.04);

    .addTags {
      opacity: 1;
      z-index: 1;
    }

    .journeyHubLink {
      visibility: visible;
    }
  }

  .listItemContent {
    display: flex;
    align-items: center;
    flex: 1;

    .avatar {
      color: white;

      &.success {
        background-color: $mui-success;
      }
      &.secondary {
        background-color: $mui-secondary;
      }

      img {
        filter: brightness(0) invert(1); // make LOI icon white
      }
    }

    .chipsContainer {
      display: flex;
      margin-left: -2px;
      gap: 0.25rem;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  .addTags {
    transition: all ease-in-out 0.2s;
    opacity: 0;
    z-index: -1;

    &_show {
      opacity: 1;
      z-index: 1;
    }

    & :global .MuiChip-label {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .author {
    color: $mui-primary;
  }

  .journeyHubLink {
    visibility: hidden;
  }
}
