@import 'src/styles/colors';

.root {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .right {
      display: flex;
      align-items: center;
    }
  }
}

.metadata {
  font-size: 0.875rem;
  font-style: italic;
  text-align: right;

  .author {
    color: $mui-primary;
  }
}

.registrationAlert :global(.MuiAlert-message) {
  width: 100%;
}
