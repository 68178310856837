/*
 * Media query mixins for different screen sizes.
 */

$desktop-width: 768px;

@mixin mobile {
  @media (max-width: #{$desktop-width - 1}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin iPhone5 {
  // this one is extra small
  @media (max-width: 320px) {
    @content;
  }
}
