.small {
  margin-top: 1rem;

  .header {
    font-size: 1rem;
    font-weight: 600;
  }

  .message {
    font-size: 1rem;
    margin-top: 0.5rem;
  }
}

.large {
  margin-top: 1.5rem;

  .header {
    font-size: 3rem;
  }

  .message {
    font-size: 1.5rem;
    margin-top: 1rem;
  }
}

.error {
  margin-top: 0.5rem;

  :global(.MuiAlertTitle-root) {
    font-weight: 600;
  }
}
