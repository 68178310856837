@import 'src/styles/colors';

.root {
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 4px;

  pre {
    font-size: 1rem;
    word-break: break-word;
    white-space: break-spaces;
    margin: 0 0 8px 0;
  }

  .grey {
    background-color: $cool-grey;
  }
}
