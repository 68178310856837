@import 'src/styles/colors';

.fieldGroupTitle {
  font-size: 15px;
  margin-top: 1rem;
}

.actions {
  display: flex;
  justify-content: space-between;

  .errorLink {
    font-size: 1rem;
  }
}
