.campaign {
  position: relative;

  .terminationConfig {
    position: absolute;
    display: flex;
    align-content: flex-start;
    top: 4.75rem;
    left: 0.1rem;
    width: max-content;

    .datePicker input {
      width: 12ch;
      padding: 0.5rem 1rem;
    }
  }

  .nextActionConfig {
    position: absolute;
    display: flex;
    align-content: flex-start;
    top: 7.9rem;
    width: max-content;
  }
}
