.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.content {
  display: flex;

  .list {
    width: 100%;
  }
}

.campaign_tools {
  display: flex;
  margin-bottom: 2rem;
}
